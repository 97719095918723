import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pco-perguntas-abertas"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,[_c(VDataTable,{staticClass:"table-kitchen-sink",attrs:{"headers":_vm.headers,"items":_vm.listaPerguntas,"search":_vm.searchTextField,"items-per-page":5,"loading":(_vm.listaPerguntas.length == 0) ? true : false,"items-per-page-text":"Linhas por página","no-results-text":"Nenhum registro encontrado","loading-text":"Carregando... Por favor, espere","footer-props":{
                showFirstLastPage: true,           
                  'items-per-page-text':'Linhas por página'
              },"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('label',[_vm._v("Nenhum registro disponível")])]},proxy:true},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" de "+_vm._s(items.itemsLength)+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }