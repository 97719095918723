<template>
  <div class="pco-perguntas-abertas">
    <v-row>
      <v-col cols="12">
        <v-card>          
            <v-data-table             
                :headers="headers"
                :items="listaPerguntas"
                :search="searchTextField"
                :items-per-page="5"
                :loading="(listaPerguntas.length == 0) ? true : false"
                class="table-kitchen-sink"
                items-per-page-text="Linhas por página"
                no-results-text="Nenhum registro encontrado"
                loading-text="Carregando... Por favor, espere"     
                :footer-props="{
                  showFirstLastPage: true,           
                    'items-per-page-text':'Linhas por página'
                }"  
                mobile-breakpoint="0"
            >
                <!-- <template v-slot:[`item.acesso_master`]="{ item }">
            <v-switch
                :loading="loadingControl.master[`${item.id}`]"
                input-value="false"
                :true-value="'S'"
                :false-value="'N'"
                v-model="item.acesso_master"
                :label="item.acesso_master == 'S' ? 'Sim' : 'Não'"
                @change="editarSwitch(item, 'master')"
            ></v-switch>
            </template>
            <template v-slot:[`item.libera_acesso`]="{ item }">
            <v-switch
                :loading="loadingControl.libera[`${item.id}`]"
                input-value="false"
                :true-value="'S'"
                :false-value="'N'"
                v-model="item.libera_acesso"
                :label="item.libera_acesso == 'S' ? 'Sim' : 'Não'"
                @change="editarSwitch(item, 'libera')"
            ></v-switch>
            </template>
            <template v-slot:[`item.status`]="{ item }">
            <v-switch
                :loading="loadingControl.status[`${item.id}`]"
                input-value="false"
                :true-value="'A'"
                :false-value="'I'"
                v-model="item.status"
                :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                @change="editarSwitch(item, 'status')"
            ></v-switch>
            </template>
            <template v-slot:[`item.acao`]="{ item }">
            <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
                </template>

                <v-list>
                <v-list-item @click="toggleModalLateral(item)">
                    <v-list-item-title>
                    <v-icon size="20" class="me-2"> mdi-eye </v-icon>
                    <span>Visualizar</span>
                    </v-list-item-title>
                </v-list-item>

                <v-list-item @click="editar(item)">
                    <v-list-item-title>
                    <v-icon size="20" class="me-2"> mdi-pencil </v-icon>
                    <span>Editar</span>
                    </v-list-item-title>
                </v-list-item>

                <v-list-item @click="remover(item, true)">
                    <v-list-item-title>
                    <v-icon size="20" class="me-2"> mdi-delete </v-icon>
                    <span>Apagar</span>
                    </v-list-item-title>
                </v-list-item>
                </v-list>
            </v-menu>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ item.created_at | dateTimeFormatBr() }}</span>
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
            <span>{{ item.updated_at | dateTimeFormatBr() }}</span>
            </template> -->
                <template v-slot:no-data>
                  <label>Nenhum registro disponível</label>
                </template>
                <template v-slot:[`footer.page-text`]="items"> 
                  {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
                </template>
            </v-data-table>         
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from "@/store";

import { computed, ref } from "@vue/composition-api";
import themeConfig from "@themeConfig";

export default {
  components: {},
  setup() {
    const searchTextField = ref("");
    const loadingControl = ref(true);
    // const $themeColors = themeConfig.themes.light;
    const headers = [
      { text: "Código", value: "token", sortable: true },
      { text: "Pergunta", value: "pergunta", sortable: false },
      { text: "Resposta", value: "resposta", sortable: false },
    ];
    // const computedHeaders = computed(() => {
    //     return headers.filter(
    //         (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
    //     );
    // })
    const listaPerguntas = computed(
      () => store.getters["pesquisas/getListaPerguntasAbertas"]
    );

    return {
      searchTextField,
      loadingControl,
      listaPerguntas,
      headers
    };
  },
};
</script>

<style>
</style>